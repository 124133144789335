import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { Link } from 'react-router'
// import { isAuthenticatedForQualityFramework } from './auth'
import home from 'images/side-navigation/home.svg'
import map from 'images/side-navigation/map.svg'
// import reports from 'images/side-navigation/pie-chart.svg'
// import framework from 'images/side-navigation/shield.svg'
import faq from 'images/side-navigation/faq.svg'
import { PARTNER_PORTAL_ROUTE } from 'config'
import {
  CommunityImage,
  ExternalLink,
  PortalNavAdditionalLogos,
} from 'components'
import classnames from 'classnames'

const propTypes = {
  community: PropTypes.object.isRequired,
  currentSchoolYear: Types.schoolYear.isRequired,
  closeMobileNav: PropTypes.func.isRequired,
}

const defaultProps = {}

function SideNav({ community, currentSchoolYear, closeMobileNav }) {
  const { faqUrl, defaultBranding } = community
  const { communityLogo, secondarySideBySideLogo } = defaultBranding

  return (
    <nav className="side-navigation" aria-label="Primary Navigation">
      <div className="side-navigation-inner">
        <div className="logo-block">
          <div
            className={classnames('logo-block-inner', {
              'side-by-side': secondarySideBySideLogo,
            })}
          >
            <CommunityImage image={communityLogo} />
            {secondarySideBySideLogo && (
              <div className="second-logo">
                <CommunityImage image={secondarySideBySideLogo} />
              </div>
            )}
          </div>
        </div>
        <ul>
          <li>
            <Link
              to={PARTNER_PORTAL_ROUTE + '/dashboard'}
              onClick={closeMobileNav}
            >
              <img src={home} alt="Home Icon" /> Home
            </Link>
          </li>
          <li>
            <Link
              to={PARTNER_PORTAL_ROUTE + '/profile'}
              data-cy="profile-information"
              onClick={closeMobileNav}
            >
              <img src={map} alt="Map Icon" data-cy="partner-profile-link" />{' '}
              {currentSchoolYear.number} artlook Map Profile
            </Link>
          </li>
          {/* <li><Link to={ PARTNER_PORTAL_ROUTE + '/reports' }><img src={ reports } alt="Reports Icon" /> Reports</Link></li> */}
          {/* {
            isAuthenticatedForQualityFramework() &&
            <li><Link to="/quality-framework"><img src={ framework } alt="Framework Icon" /> Quality Framework</Link></li>
          } */}
          {faqUrl && (
            <li>
              <ExternalLink href={faqUrl} onClick={closeMobileNav}>
                <img src={faq} alt="" /> FAQ
              </ExternalLink>
            </li>
          )}
        </ul>
      </div>

      <PortalNavAdditionalLogos community={community} />
    </nav>
  )
}

SideNav.propTypes = propTypes
SideNav.defaultProps = defaultProps

export default SideNav
