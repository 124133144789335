import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import * as Types from 'types'

const propTypes = {
  value: PropTypes.string,
  data: PropTypes.shape({
    organizationId: PropTypes.number.isRequired,
    schoolId: PropTypes.number.isRequired,
    type: PropTypes.oneOf([Types.PARTNER_TYPE, Types.SCHOOL_TYPE]).isRequired,
    schoolYearId: PropTypes.number.isRequired,
  }),
}

function PartnerTableLinkCell({
  value,
  data: { schoolId, organizationId, type, schoolYearId },
}) {
  const url = type === Types.PARTNER_TYPE ? '/partners' : '/schools'
  const id = type === Types.PARTNER_TYPE ? organizationId : schoolId
  return (
    <td>
      <Link
        to={{
          pathname: `${url}/${id}`,
          search: `?year=${schoolYearId}`,
        }}
        data-cy="partner-table-link"
      >
        {value}
      </Link>
    </td>
  )
}

PartnerTableLinkCell.propTypes = propTypes

export default PartnerTableLinkCell
