import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, HiddenSpan } from 'components'
import * as Types from 'types'
import { getFormWideErrorMessage } from 'utils'
import { AddPartnerForm, AddPendingPartnerForm } from '../forms'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  partnerOptions: PropTypes.arrayOf(Types.searchSuggestion),
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  existingPartnerships: PropTypes.arrayOf(Types.schoolPartnershipType),
}

const defaultProps = {
  partnerOptions: [],
  existingPartnerships: [],
}

function nameForOrgId(orgId, partnerOptions) {
  return partnerOptions.find(({ id }) => id == orgId).name
}

function AddPartnershipModal({
  onClose,
  partnerOptions,
  onSubmit,
  onSubmitSuccess,
  existingPartnerships,
}) {
  const [formWideError, setFormWideError] = useState(null)
  const [submitFailCount, setSubmitFailCount] = useState(0)
  return (
    <Modal onClose={onClose}>
      <h2>Add New Partnership</h2>
      <div className="modal-content">
        <div role="alert" aria-atomic>
          {formWideError && (
            <p className="full-error-message">
              {/* The hidden count will force screen readers to announce
               the error again even when it's exactly the same */}
              {formWideError} <HiddenSpan>{submitFailCount}</HiddenSpan>
            </p>
          )}
        </div>
        <AddPartnerForm
          partnerOptions={partnerOptions}
          onSubmit={({ organizationId }, ...rest) =>
            onSubmit(
              {
                organizationId,
                organizationName: nameForOrgId(organizationId, partnerOptions),
              },
              false,
              ...rest
            )
          }
          onSubmitFail={(errors, _, submitError) => {
            setSubmitFailCount((sfc) => sfc + 1)
            const msg = getFormWideErrorMessage(errors, submitError)
            setFormWideError(msg)
          }}
          onSubmitSuccess={(params, ...rest) =>
            onSubmitSuccess(params, false, ...rest)
          }
          existingPartnerships={existingPartnerships}
        />
        <br />
        <p id="pending-partner-label">
          If you cannot find the partner you wish to add in the list of known
          partners above, then you may enter the partner's name below instead.
          This will create a "pending" partnership and will alert the
          administrator to review the information you have provided.
        </p>
        <p>
          Please try other variations of the name (e.g. MADD Rhythms as M.A.D.D.
          Rhythms) before submitting a pending partnership.
        </p>
        <AddPendingPartnerForm
          onSubmit={(params, ...rest) => onSubmit(params, true, ...rest)}
          onSubmitSuccess={(params, ...rest) =>
            onSubmitSuccess(params, true, ...rest)
          }
          onSubmitFail={(errors, _, submitError) => {
            setSubmitFailCount((sfc) => sfc + 1)
            const msg = getFormWideErrorMessage(errors, submitError)
            setFormWideError(msg)
          }}
          ariaLabelledby="pending-partner-label"
        />
      </div>
    </Modal>
  )
}

AddPartnershipModal.propTypes = propTypes
AddPartnershipModal.defaultProps = defaultProps

export default AddPartnershipModal
