import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as Types from 'types'
import { ArtsLiaisonForm, AccessibleProgramsForm } from '../forms'
import * as apiActions from 'api-actions'
import { DashboardMap } from '../components'
import { Spinner } from 'lp-components'
import {
  serializeLocation,
  replaceResources,
  displaySubmitFailure,
} from 'utils'
import * as flashActions from 'redux-flash'
import { startCase, get, reduce, flatMap, values, filter } from 'lodash'
import { set } from 'lodash/fp'
import { WHATS_HAPPENING_NOW_TEXT } from 'config'

const propTypes = {
  school: Types.school.isRequired,
  detail: Types.detail.isRequired,
  location: Types.location,
  liaisonPosition: Types.position,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  participation: Types.participation,
  flashSuccessMessage: PropTypes.func.isRequired,
  updateSchool: PropTypes.func.isRequired,
  fetchPositions: PropTypes.func.isRequired,
}

const defaultProps = {
  liaisonPosition: null,
  participation: null,
  location: null,
}

function getDisciplineForProgram(program, disciplines) {
  const subDisciplineId = program.subDisciplineId || program.id
  return disciplines.find(({ subDisciplines }) =>
    subDisciplines.some(({ id }) => id === subDisciplineId)
  )
}

function groupProgramsByDiscipline(programs, disciplines) {
  return reduce(
    programs,
    (disciplinesCollection, program) => {
      const disciplineName = getDisciplineForProgram(program, disciplines).name
      return set(
        `${disciplineName}.${program.name}`,
        program,
        disciplinesCollection
      )
    },
    {}
  )
}

function getListOfAccessiblePrograms(programsGroupedByDiscipline) {
  const programs = flatMap(programsGroupedByDiscipline, values)
  return filter(
    programs,
    ({ tag }) => tag !== Types.SUB_DISCIPLINE_OFFER_TYPES.NONE
  )
}

function Overview({
  school,
  location,
  liaisonPosition,
  detail,
  disciplines,
  participation,
  flashSuccessMessage,
  updateSchool,
  fetchPositions,
}) {
  useEffect(() => {
    if (!liaisonPosition) fetchPositions()
  }, [liaisonPosition])

  const updateSchoolOnSubmit = useCallback(
    (params) => updateSchool(school.id, currentSchoolYearId, params),
    [school, currentSchoolYearId]
  )
  const onSuccess = useCallback(
    () => flashSuccessMessage(Types.SCHOOL_SUCCESS_MESSAGE),
    [flashSuccessMessage]
  )

  if (!liaisonPosition || !location) return <Spinner />

  const { displayName, category } = school
  const { street, city, state, zip } = location
  const currentSchoolYearId = detail.schoolYearId

  return (
    <div>
      <p>{WHATS_HAPPENING_NOW_TEXT}</p>
      <section className="card card-flex">
        <div className="address-block" data-cy="school-details-container">
          <h2>Your School</h2>
          <ul className="location-details-block">
            <li>
              <p>
                <strong>Name: </strong>
                {displayName}
              </p>
            </li>
            <li>
              <p>
                <strong>School Type: </strong>{' '}
                {category ? startCase(category) : 'Unknown.'}
              </p>
            </li>
            <li>
              <p>
                <strong>Address:</strong> {street} {city} {state} {zip}
              </p>
            </li>
            <li>
              <p>
                <strong>Enrollment Number: </strong>
                {get(participation, 'enrollment', 'None found.')}
              </p>
            </li>
          </ul>
        </div>
        <DashboardMap
          location={serializeLocation(Types.SCHOOL_TYPE, school)}
          hidePopup
        />
      </section>
      <section className="card">
        <h2>Arts Liaison</h2>
        <ArtsLiaisonForm
          onSubmit={(params) =>
            updateSchoolOnSubmit({
              contacts: [
                {
                  ...params.contacts,
                  positionId: liaisonPosition.id,
                  schoolYearId: currentSchoolYearId,
                },
              ],
            })
          }
          initialValues={{ contacts: school.artsLiaison }}
          onSubmitSuccess={onSuccess}
          onSubmitFail={displaySubmitFailure}
        />
      </section>
      <section className="card">
        <h2>
          Please select the types of arts programs that students at your school
          have access to and whether those programs are offered in school, out
          of school, or both.
        </h2>
        <AccessibleProgramsForm
          disciplines={disciplines}
          initialValues={{
            programsByDiscipline: groupProgramsByDiscipline(
              school.accessiblePrograms,
              disciplines
            ),
          }}
          onSubmit={({ programsByDiscipline }) => {
            const resources = replaceResources({
              old: school.accessiblePrograms,
              new: getListOfAccessiblePrograms(programsByDiscipline),
            })
            return updateSchoolOnSubmit({ subDisciplines: resources })
          }}
          onSubmitSuccess={onSuccess}
        />
      </section>
    </div>
  )
}

Overview.propTypes = propTypes
Overview.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    location: globalSchoolSelectors.currentSchoolLocation(state),
    detail: globalSchoolSelectors.currentDetail(state),
    school: globalSchoolSelectors.school(state),
    liaisonPosition: globalSchoolSelectors.liaisonPosition(state),
    participation: globalSchoolSelectors.currentParticipation(state),
    disciplines: globalSelectors.definedDisciplines(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashActions.flashSuccessMessage,
  updateSchool: apiActions.updateSchool,
  fetchPositions: apiActions.fetchPositions,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Overview)
