import logo from 'images/communities/Maryland/logo.svg'
import logoWhite from 'images/communities/Maryland/logo-white.svg'
import dashboardHeaderImage from 'images/communities/Maryland/banner.jpg'
import loginBackgroundImg from 'images/communities/Maryland/background-image.jpg'
import mapHeaderImage from 'images/communities/Maryland/landing.jpg'
import favicon from 'images/communities/Maryland/favicon.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'maryland',
  partnerSupportEmail: 'artlookmd@aems-edu.org',
  schoolSupportEmail: 'artlookmd@aems-edu.org',
  stylesheet: 'maryland.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://aems-edu.org/',
  faqUrl:
    'https://drive.google.com/file/d/1pmv8TkvcJSf51iZUlgSB2AVDIgmjVV-g/view?usp=sharing',
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: false,
  notificationsEnabled: false,
  id: 6,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory'],
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 39.1227, lng: -76.6465412 },
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'md-community',
  favicon,
  mapLogo: {
    src: logoWhite,
    alt: "Maryland's homepage",
    link: config.organizationUrl,
  },
  communityLogo: {
    src: logo,
    alt: 'Artlook Schools',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: mapHeaderImage,
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

const surveyFormUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSfKO15xF-o7Igu6kgewV7MFv8op0A2ChN0-jQQe2Q3yxe_UzA/viewform?usp=sf_link'

config.displayText = {
  map: {
    title: 'artlook Map: Increasing Arts Education in Maryland',
    searchHeader: 'Find Schools & Partners in Maryland',
    welcomeSearchHeader: 'Explore Arts Education in Maryland',
    welcomeHeader: `Arts Education in Maryland Schools (AEMS) presents artlook<sup>®</sup> Maryland`,
    welcomeMessage: `
      <p>
        artlook<sup>®</sup> Maryland is a free to use, easy to navigate web platform that paints the portrait of arts education in your community.
      </p>
      <p>
        With detailed information about students' access to arts instruction (courses offered, minutes of instruction, additional programming, and more) this universally-accessible data can help families, educators, advocates, funders, and legislators write a compelling script when pushing for greater access to quality arts education for all Maryland public school students.
      </p>
      <p>
        artlook<sup>®</sup> Maryland also sets the stage for collaborations and connections with organizations and artists who can offer arts education experiences to students by displaying Arts Partner profiles, thereby providing further opportunities for access for children.
      </p>
      <p>
        Explore artlook<sup>®</sup> Maryland to find out the state of arts education in your district by using the search bar above! If you are a teacher or arts partner with a profile, click on the School or Partner Portal links to log in and access your account.
      </p>
      <p>
        artlook<sup>®</sup> Maryland is piloting in Anne Arundel County, Baltimore City, Montgomery County, and Queen Anne's County, with plans to bring access to the whole state. If you don't see your district represented here, <a href="mailto:artlookmd@aems-edu.org" target="_blank" rel="noopener noreferrer">reach out to AEMS</a> to learn more about how to put your community on the map!
      </p>
    `,
  },
  tagline:
    'Bringing better access, equity, and quality to arts education in Maryland',
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Maryland</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> Maryland</em> is the only platform of its kind that combines data from Maryland public schools and arts education program providers (like you!). The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY Maryland student.
        </p>
        <p>
          To learn more about how <em>artlook<sup>®</sup> Maryland</em> can support your work, visit <a href="${config.organizationUrl}/artlook-maryland" target="_blank" rel="noopener noreferrer">aems-edu.org/artlook-maryland</a>. If you have questions or need assistance, please contact <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>.
        </p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Maryland</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> Maryland</em> is the only platform of its kind that combines data from Maryland public schools (like yours!) and arts education program providers. The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY student in our community.
        </p>
        <p>
          <strong>To get started, follow the link below to provide real-time updates about what's happening at your school. Then, using the navigation links on the left, verify information about your school's arts education offerings and partnerships by completing the {{ surveySchoolYearNumber }} Survey.</strong>
        </p>
        <p>
          To learn more about how <em>artlook<sup>®</sup> Maryland</em> can support your work, visit <a href="${config.organizationUrl}/artlook-maryland" target="_blank" rel="noopener noreferrer">aems-edu.org/artlook-maryland</a>. If you have questions or need assistance, please contact <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.
        </p>
      `,
    },
  },
  survey: {
    getStarted: `
      <h2>Welcome to the artlook<sup>®</sup> Maryland {{ surveySchoolYearNumber }} Survey!</h2>
      <p>
        Through this annual survey, you will be asked to share information about arts education staffing and instruction at your school, partnerships with arts partners (i.e., arts organizations and teaching artists), and how your school budgets and plans for the arts.
      </p>
      <p>
        <strong>Because of learning disruptions and differing instructional formats in the 2019-20 school year we ask you to <em>only consider courses, programs, and arts instruction from the beginning of the school year (September 2019) through the closure of in-person instruction (March 2020)</em>. If there were significant changes to arts education at your school after March 2020, please let us know by completing <a href="${surveyFormUrl}" target="_blank" rel="noopener noreferrer">this form.</a></strong>
      <p>
        Please complete as much of the {{ surveySchoolYearNumber }} survey as possible. If there are questions you are unsure about, feel free to reach out to other members of your school community for support or just move on to the next question. You are not required to complete your survey in one session; so long as your question responses are saved, you can return at any time to edit your survey before submitting.
      </p>
      <p>
        Please reach out to <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a> with any questions!
      </p>
    `,
    coursesNote: `
      <p>
        <strong>Note on courses:</strong> An arts class meets the following criteria: (1) it is offered to students during the school day, (2) it is categorized as an arts class or it primarily teaches the fine arts standards, and (3) it is taught by a certified or endorsed arts instructor.
      </p>
    `,
    staffingModal: `
      <p>
        <strong>Note on instructors</strong>: Restrict the list of instructors in your school to those teaching an arts course during the school day. Note that district-run/district-managed schools require arts instructors to hold a state certification or endorsement in one of the following arts disciplines: visual arts, media arts, music, theater, and/or dance. Charter schools, contract schools, and Pre-K-only schools do not have this requirement. If itinerant teachers teach at your school, please report those individuals as well.
      </p>
      <p>
        <strong>Note on part/full-time status</strong>: Please select Full Time if a teacher had full-time status at your school for the entire school year. If a teacher was part-time at your school for all or part of the year, or full-time for only part of the year, please select Part Time.
      </p>
      <p>
        <strong>Note on disciplines</strong>: The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
      </p>
    `,
  },
}

// ----- SURVEY QUESTIONS CONFIG -----

const {
  APPROACHES,
  INTEGRATIONS,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.PER_DISCIPLINE,
  integrations: INTEGRATIONS.OPTIONS.GENERAL,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

export default config
