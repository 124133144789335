import React from 'react'
import PropTypes from 'prop-types'
import blackCheck from 'images/black-check.svg'
import { toggle, togglePropTypes } from 'lp-hoc'
import { compose } from 'redux'

const propTypes = {
  children: PropTypes.node,
  content: PropTypes.string.isRequired,
  label: PropTypes.string,
  hideExpand: PropTypes.bool,
  ...togglePropTypes('expanded'),
}

const defaultProps = {
  hideExpand: false,
  label: '',
}

function StatsListListItem({
  content,
  label,
  children,
  hideExpand,
  toggleExpanded,
  expanded,
}) {
  const contentArr = content.split(',')
  return (
    <li>
      <div className="stat-block card">
        <div className="stat-inner">
          <ul className="with-small-checkmarks">
            {contentArr.map((item) => {
              return (
                <li key={item}>
                  <img src={blackCheck} alt="Yes" />
                  {item}
                </li>
              )
            })}
          </ul>
          {!hideExpand && (
            <button
              onClick={toggleExpanded}
              className="stat-trigger"
              aria-label={`Toggle ${label} Details`}
              aria-expanded={expanded}
              aria-controls={`stat-item-${content}`}
            />
          )}
        </div>
        <div id={`stat-item-${content}`}>
          {children && expanded && (
            <div className="stat-details">
              <div className="stat-content">{children}</div>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

StatsListListItem.propTypes = propTypes
StatsListListItem.defaultProps = defaultProps

export default compose(toggle('expanded'))(StatsListListItem)
