import logo from 'images/communities/Oregon/logo.svg'
import dashboardHeaderImage from 'images/communities/Oregon/banner2.jpeg'
import loginBackgroundImg from 'images/communities/Oregon/background-image2.jpeg'
import favicon from 'images/communities/Oregon/favicon.ico'
import homeLogo from 'images/home/community-logos/oregon.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'oregon',
  name: 'Oregon',
  position: 3,
  partnerSupportEmail: 'artlooksupport@racc.org',
  schoolSupportEmail: 'artlooksupport@racc.org',
  stylesheet: 'oregon.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://racc.org',
  raccUrl: 'https://racc.org/arts-education/',
  faqUrl:
    'https://drive.google.com/file/d/1o0j912K_vjADCVNkFBM7umSLrcdQxk1B/view?usp=sharing',
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: false,
  notificationsEnabled: false,
  id: 7,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory'],
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 45.5507268, lng: -122.671822 },
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'oregon-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: "RACC's homepage",
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'Regional Arts and Culture Council',
  },
  communityLogo: {
    src: logo,
    alt: '',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    attribution: 'Photo courtesy of Haruka Ashida Ostley, Journey to Peace',
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    attribution: 'Photo courtesy of Gary Hirsch, Questions for Humans',
    alt: '',
  },
  mapHero: {
    src: dashboardHeaderImage,
    attribution: 'Photo courtesy of Haruka Ashida Ostley, Journey to Peace',
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  tagline:
    "Let's increase arts and culture education access, equity and inclusivity in our community!",
  map: {
    title: 'artlook Map: Increasing Arts Education in the Oregon',
    tagline:
      'Arts Education begins with access, equity, and inclusivity. Explore artlook<sup>®</sup> oregon',
    searchHeader: 'Find Schools & Partners in Oregon',
    welcomeSearchHeader:
      'Explore Arts & Culture Educational programming in your community',
    welcomeHeader: `artlook<sup>®</sup> oregon`,
    welcomeMessage: `
      <p>
        In 2020, we launched artlook<sup>®</sup> in our community, developed with initial collaborators Portland Public Schools, Parkrose School District, and 66 Original arts partners. We have grown our platform; and we continue to expand to include more districts and arts partners throughout our state, neighborhoods, and communities.
      </p>
      <p>
        This platform makes visible the arts and culture education assets in schools; to which arts partners are in which schools, to the educational programming, course offerings, contact information, and minutes of instruction in schools.
      </p>
      <p>
        As we continue to grow to include more school districts and arts partners, artlook<sup>®</sup> will be a rich resource for strategic decision making about arts education in our K-12 schools. Schools must complete surveys and arts partners must recognize school partnerships. We understand that some partnerships may not be updated to reflect what is available at the time you look on artlook<sup>®</sup> and encourage you to return, since the platform is frequently updated.
      </p>
      <p>
        Start exploring artlook<sup>®</sup> today, and help us expand arts and culture education access, equity, and inclusivity for every child in the state of Oregon.
      </p>
    `,
  },
  partnerPortal: {
    dashboard: {
      welcomeMessage: `<h3><em>artlook<sup>®</sup> oregon</em> is YOUR gallery for arts/culture education data.</h3>
        <p>
          <em>artlook<sup>®</sup> oregon</em> is the only platform of its kind that combines data from public schools and arts/culture education program providers (like you!). The goal is to build direct connections between arts education providers and schools to expand equitable access to an inclusive quality arts/culture education programming for EVERY student in our community.
        </p>
        <p>
          <strong>To get started, follow the links in the boxes below to share your organization's program and school partnership information for the school year. <em>If you have no updates, you can simply rollover from the previous year.<em></strong>
        </p>
        <p>
          If you have any questions or need assistance, please contact <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>. For more information, please visit the <a href="${config.raccUrl}" target="_blank" rel="noopener noreferrer">Regional Arts & Culture Council</a> for downloadable documents.
        </p>`,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `<h3><em>artlook<sup>®</sup> oregon</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> oregon</em> is the only platform of its kind that combines data from public schools (like you!) and arts/culture education program providers. The goal is to build direct connections between arts providers and schools to expand equitable access to an inclusive quality arts/culture education programming for EVERY student in our community.
        </p>
        <p>
          <strong>To get started, follow the link below to complete a brief survey about your school's arts education offerings and partnerships for the most recent school survey year.</strong>
        </p>
        <p>
          If you have any questions or need assistance, please contact <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>. For more information, please visit the <a href="${config.raccUrl}" target="_blank" rel="noopener noreferrer">Regional Arts & Culture Council</a> for downloadable documents.
        </p>`,
    },
  },
  survey: {
    getStarted: `<h2>Welcome to the artlook<sup>®</sup> oregon Survey!</h2>
    <p>
      Through this annual survey, you will be asked to share information about arts education staffing and instruction at your school, partnerships with arts partners (i.e. arts organizations), and how your school budgets and plans for the arts.
    </p>
    <p>
      As you work on the {{ surveySchoolYearNumber }} survey, please be sure to save your responses to each question to avoid losing information. You are not required to complete your survey in one session; so long as your question responses are saved, you can return at any time to edit your survey before submitting.
    </p>
    <p>
      Please feel free to reach out to <a href="mailto:artlooksupport@racc.org" target="_blank" rel="noopener noreferrer">artlooksupport@racc.org</a> with any questions!
    </p>`,
    staffingModal: `<p>
        <strong>Note on instructors</strong>: Restrict the list of instructors in your school to those teaching an arts course during the school day. Note that district-run/district-managed schools require arts instructors to hold a state certification or endorsement in one of the following arts disciplines: visual arts, media arts, music, theater, and/or dance. Charter schools, contract schools, and Pre-K-only schools do not have this requirement. If itinerant teachers teach at your school, please report those individuals as well.
      </p>
      <p>
        <strong>Note on part/full-time status</strong>: Please select Full Time if a teacher had full-time status at your school for the entire school year. If a teacher was part-time at your school for all or part of the year, or full-time for only part of the year, please select <strong>Part Time</strong>. For example: If a teacher is full time because a portion of their FTE is in a non-arts subject (such as .8 music and .2 health), please select <strong>Part Time</strong>.
      </p>
      <p>
        <strong>Note on disciplines</strong>: The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
      </p>
    `,
  },
}

// ----- SURVEY QUESTIONS CONFIG -----

const { GOVERNANCE, OBSTACLES_SELECT, OBSTACLES_DESCRIBE, OBSTACLES_OVERCOME, COMMUNITY_ENGAGEMENTS, } =
  SURVEY_QUESTIONS

config.surveyQuestions = {
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

export default config
