import {
  createRequest,
  createPostRequest,
  createPatchRequest,
  createDeleteRequest,
  // stubRequest,
} from 'lp-redux-api'
import * as LS from 'local-storage'
import {
  serializePartnerParams,
  serializeSchoolParams,
  serializeProfileParams,
  generateMappedFilters,
  getPortalAuthToken,
  delayedRedirectToUrl,
  formatAnnouncementDate,
  serializePostsSort,
  setBookmarkedFilter,
  removeEmptyFilters,
  serializeAttachments,
} from 'utils'
import * as Types from 'types'
import {
  SEARCH_PAGE_SIZE,
  DISCUSSIONS_PAGE_SIZE,
  DIRECTORY_PAGE_SIZE,
  USER_BOOKMARK_MESSAGING,
  POST_BOOKMARK_MESSAGING,
  FLAG_MESSAGE,
  MEMBERSHIP_MESSAGE,
  ANNOUNCEMENT_MESSAGE,
  ATTACHMENT_MESSAGE,
  FOLDER_MESSAGE,
} from 'config'
import { isEmpty } from 'lodash'

// Map actions
export const fetchSearchResults = createRequest(
  'FETCH_SEARCH_RESULTS',
  ({
    query,
    type,
    filters,
    schoolYear,
    sortType = Types.RELEVANCE_SORT_TYPE,
    page = 1,
  }) => {
    const url =
      type === Types.SCHOOL_TYPE ? '/school_search' : '/organization_search'
    const mappedFilters = generateMappedFilters({ filters, type, schoolYear })
    const direction = sortType === Types.ALPHABETICAL_SORT_TYPE ? 'asc' : 'desc'

    return {
      url: url,
      query: {
        query,
        page,
        sort: `{ "${sortType}": "${direction}" }`,
        filters: JSON.stringify(mappedFilters),
        limit: SEARCH_PAGE_SIZE,
      },
    }
  }
)

export const fetchSearchOptions = createRequest(
  'FETCH_SEARCH_OPTIONS',
  (
    { blockList = [] } = {},
    type = Types.SEARCH_OPTIONS_TYPE.MAP,
    districtId = null
  ) => ({
    url: '/search_options',
    query: {
      blockList: isEmpty(blockList) ? undefined : JSON.stringify(blockList),
      districtId,
    },
    onSuccess: (searchOptions) => ({
      searchOptions,
      type,
    }),
  })
)

export const fetchSchool = createRequest(
  'FETCH_SCHOOL',
  (id, schoolYearId) => ({
    url: `/schools/${id}`,
    query: { school_year_id: schoolYearId },
  })
)

export const fetchPartner = createRequest(
  'FETCH_PARTNER',
  (id, schoolYearId) => ({
    url: `/organizations/${id}`,
    query: { school_year_id: schoolYearId },
  })
)

// Partner portal actions

export const fetchSchoolOptions = createRequest('FETCH_SCHOOL_OPTIONS', () => ({
  url: `/schools`,
}))

export const fetchPartnerAuthorized = createRequest(
  'FETCH_PARTNER_AUTHORIZED',
  (id, years) => ({
    url: `/partner_portal/organizations/${id}`,
    query: { years },
    token: LS.getPartnerAuthToken(),
  })
)

export const updatePartner = createPatchRequest(
  'UPDATE_PARTNER',
  (partnerId, schoolYear, partner) => {
    return {
      url: `/partner_portal/organizations/${partnerId}`,
      body: { organization: serializePartnerParams(partner, schoolYear) },
      token: LS.getPartnerAuthToken(),
    }
  }
)

export const fetchCurrentPartnerUserDetails = createRequest(
  'FETCH_CURRENT_PARTNER_USER_DETAILS',
  () => {
    return {
      url: '/authentication/current_user_details',
      token: LS.getPartnerAuthToken(),
    }
  }
)

// School portal actions
export const fetchSchoolUserDetails = createRequest(
  'FETCH_SCHOOL_USER_DETAILS',
  (userId) => ({
    url: `/school_portal/users/${userId}`,
  })
)

export const fetchPartnerOptions = createRequest(
  'FETCH_PARTNER_OPTIONS',
  () => ({
    url: `/partner_portal/organizations`,
  })
)

export const fetchSchoolAuthorized = createRequest(
  'FETCH_SCHOOL_AUTHORIZED',
  (id, years) => ({
    url: `/school_portal/schools/${id}`,
    query: { years },
    token: LS.getSchoolAuthToken(),
  })
)

export const fetchSpaceTypes = createRequest('FETCH_SPACE_TYPES', () => ({
  url: `/school_portal/space_types`,
  token: LS.getSchoolAuthToken(),
}))

export const fetchIntegrationTypes = createRequest(
  'FETCH_INTEGRATION_TYPES',
  () => ({
    url: `/school_portal/integration_types`,
    token: LS.getSchoolAuthToken(),
  })
)

export const fetchEngagementTypes = createRequest(
  'FETCH_ENGAGEMENT_TYPES',
  () => ({
    url: `/school_portal/engagement_types`,
    token: LS.getSchoolAuthToken(),
  })
)

export const fetchScheduleTypes = createRequest('FETCH_SCHEDULE_TYPES', () => ({
  url: `/school_portal/schedule_types`,
  token: LS.getSchoolAuthToken(),
}))

export const fetchSurveyAttempt = createRequest(
  'FETCH_SURVEY_ATTEMPT',
  (schoolId) => ({
    url: `/school_portal/survey_attempts`,
    query: { schoolId },
    token: LS.getSchoolAuthToken(),
  })
)

export const completeSurveyAttempt = createPatchRequest(
  'COMPLETE_SURVEY_ATTEMPT',
  (surveyAttemptId) => ({
    url: `school_portal/survey_attempts/${surveyAttemptId}/complete`,
    token: LS.getSchoolAuthToken(),
  })
)

export const updateSurveyAttemptState = createPatchRequest(
  'UPDATE_SURVEY_ATTEMPT_STATE',
  (schoolId, surveyAttemptId, state) => {
    return {
      url: `/school_portal/schools/${schoolId}`,
      body: {
        school: {
          surveyAttemptsAttributes: [
            {
              id: surveyAttemptId,
              state,
            },
          ],
        },
      },
      token: LS.getSchoolAuthToken(),
    }
  }
)

export const updateSchool = createPatchRequest(
  'UPDATE_SCHOOL',
  (schoolId, schoolYear, school) => {
    return {
      url: `/school_portal/schools/${schoolId}`,
      body: { school: serializeSchoolParams(school, schoolYear) },
      token: LS.getSchoolAuthToken(),
    }
  }
)

export const fetchFundingTypes = createRequest('FETCH_FUNDING_TYPES', () => ({
  url: `/school_portal/funding_types`,
  token: getPortalAuthToken(),
}))

export const fetchProgramTypes = createRequest('FETCH_PROGRAM_TYPES', () => ({
  url: `/program_types`,
}))

export const fetchCurrentSchoolUserDetails = createRequest(
  'FETCH_CURRENT_SCHOOL_USER_DETAILS',
  () => {
    return {
      url: '/authentication/current_user_details',
      token: LS.getSchoolAuthToken(),
    }
  }
)

// Educator portal actions

export const fetchCurrentEducatorUserDetails = createRequest(
  'FETCH_CURRENT_EDUCATOR_USER_DETAILS',
  () => {
    return {
      url: '/educator_portal/users/current_user_details',
      token: getPortalAuthToken(),
    }
  }
)

export const createOrUpdateEducatorUserProfile = createRequest(
  'CREATE_OR_UPDATE_EDUCATOR_USER_PROFILE',
  (profileId, schoolYearId, params) => {
    const baseUrl = '/educator_portal/profiles'
    const method = profileId ? 'PATCH' : 'POST'
    const url = profileId ? `${baseUrl}/${profileId}` : baseUrl

    return {
      url,
      method,
      body: serializeProfileParams(params, schoolYearId),
      token: getPortalAuthToken(),
    }
  }
)

export const fetchDirectoryUsers = createRequest(
  'FETCH_DIRECTORY_USERS',
  ({ query = '', page = 1, filters = {} }, bookmarkedUserIds) => {
    const populatedFilters = removeEmptyFilters(filters)
    const filtersWithBookmarks = setBookmarkedFilter(
      populatedFilters,
      bookmarkedUserIds
    )
    return {
      url: 'educator_portal/users/search',
      query: {
        query,
        page,
        filters: JSON.stringify(filtersWithBookmarks),
        sort: `{ "${Types.ALPHABETICAL_SORT_TYPE}": "asc" }`,
        limit: DIRECTORY_PAGE_SIZE,
      },
      token: getPortalAuthToken(),
    }
  }
)

export const fetchDirectoryUser = createRequest(
  'FETCH_DIRECTORY_USER',
  (userId) => ({
    url: `/educator_portal/users/${userId}`,
    token: getPortalAuthToken(),
  })
)

export const addBookmark = createPostRequest(
  'ADD_BOOKMARK',
  ({ bookmarkableId, bookmarkableType }) => ({
    url: '/educator_portal/bookmarks',
    body: {
      bookmark: {
        bookmarkableId,
        bookmarkableType,
      },
    },
    token: getPortalAuthToken(),
    onSuccess: (bookmarks) => {
      const successMessage =
        bookmarkableType === Types.BOOKMARKABLE.USER
          ? USER_BOOKMARK_MESSAGING.ADD.SUCCESS
          : POST_BOOKMARK_MESSAGING.ADD.SUCCESS
      return { bookmarks, successMessage }
    },
    onFailure: (error) => {
      const defaultMessage =
        bookmarkableType === Types.BOOKMARKABLE.USER
          ? USER_BOOKMARK_MESSAGING.ADD.FAIL
          : POST_BOOKMARK_MESSAGING.ADD.FAIL
      return { errorMessage: error.errors || defaultMessage }
    },
  })
)

export const removeBookmark = createDeleteRequest(
  'REMOVE_BOOKMARK',
  ({ bookmarkId, bookmarkableType }) => ({
    url: `/educator_portal/bookmarks/${bookmarkId}`,
    token: getPortalAuthToken(),
    onSuccess: (bookmarks) => {
      const successMessage =
        bookmarkableType === Types.BOOKMARKABLE.USER
          ? USER_BOOKMARK_MESSAGING.REMOVE.SUCCESS
          : POST_BOOKMARK_MESSAGING.REMOVE.SUCCESS
      return { bookmarks, successMessage }
    },
    onFailure: (error) => {
      const defaultMessage =
        bookmarkableType === Types.BOOKMARKABLE.USER
          ? USER_BOOKMARK_MESSAGING.REMOVE.FAIL
          : POST_BOOKMARK_MESSAGING.REMOVE.FAIL
      return { errorMessage: error.errors || defaultMessage }
    },
  })
)

export const addLike = createPostRequest(
  'ADD_LIKE',
  ({ likeableId, likeableType }) => ({
    url: 'educator_portal/likes',
    body: {
      like: {
        likeableId,
        likeableType,
      },
    },
    token: getPortalAuthToken(),
  })
)

export const destroyLike = createDeleteRequest('DESTROY_LIKE', ({ id }) => ({
  url: `/educator_portal/likes/${id}`,
  token: getPortalAuthToken(),
}))

export const fetchPosts = createRequest(
  'FETCH_POSTS',
  ({ query, page = 1, filters, sortType }, bookmarkedPostIds) => {
    const { type, direction } = serializePostsSort(sortType)
    const populatedFilters = removeEmptyFilters(filters)
    const filtersWithBookmarks = setBookmarkedFilter(
      populatedFilters,
      bookmarkedPostIds
    )
    return {
      url: '/educator_portal/posts/search',
      query: {
        query,
        page,
        filters: JSON.stringify(filtersWithBookmarks),
        sort: `{ "${type}": "${direction}" }`,
        limit: DISCUSSIONS_PAGE_SIZE,
      },
      token: getPortalAuthToken(),
    }
  }
)

export const fetchPostTopics = createRequest('FETCH_POST_TOPICS', () => ({
  url: '/educator_portal/topics',
  token: getPortalAuthToken(),
}))

export const fetchMentionSuggestions = createRequest(
  'FETCH_MENTION_SUGGESTIONS',
  () => ({
    url: '/educator_portal/mention_options',
    token: getPortalAuthToken(),
  })
)

export const createOrUpdatePost = createRequest(
  'CREATE_OR_UPDATE_POST',
  (post, postId, communityOfPracticeId) => {
    // strip out any post attributes that aren't required for submission
    const { title, content, category, disciplines, topics, attachments } = post
    const baseUrl = '/educator_portal/posts'
    const method = postId ? 'PATCH' : 'POST'
    const url = postId ? `${baseUrl}/${postId}` : baseUrl

    return {
      url,
      method,
      body: {
        post: {
          title,
          content,
          category,
          postDisciplinesAttributes: disciplines,
          postTopicsAttributes: topics,
          attachmentsAttributes: serializeAttachments(attachments),
          communityOfPracticeId,
        },
      },
      token: getPortalAuthToken(),
    }
  }
)

export const archivePost = createPatchRequest('ARCHIVE_POST', (postId) => ({
  url: `/educator_portal/posts/${postId}/archive`,
  token: getPortalAuthToken(),
}))

export const lockPost = createPatchRequest('LOCK_POST', (postId) => ({
  url: `/educator_portal/posts/${postId}/lock`,
  token: getPortalAuthToken(),
}))

export const reopenPost = createPatchRequest('REOPEN_POST', (postId) => ({
  url: `/educator_portal/posts/${postId}/reopen`,
  token: getPortalAuthToken(),
}))

export const fetchPost = createRequest('FETCH_POST', (postId) => ({
  url: `/educator_portal/posts/${postId}`,
  token: getPortalAuthToken(),
}))

export const createComment = createPostRequest(
  'CREATE_COMMENT',
  (commentableId, commentableType, content, attachments) => ({
    url: '/educator_portal/comments',
    body: {
      comment: {
        commentableType,
        commentableId,
        content,
        attachmentsAttributes: serializeAttachments(attachments),
      },
    },
    token: getPortalAuthToken(),
  })
)

export const updateComment = createPatchRequest(
  'UPDATE_COMMENT',
  (commentId, content, attachments) => ({
    url: `/educator_portal/comments/${commentId}`,
    body: {
      comment: {
        content,
        attachmentsAttributes: serializeAttachments(attachments),
      },
    },
    token: getPortalAuthToken(),
  })
)

export const fetchComments = createRequest('FETCH_COMMENTS', (postId) => ({
  url: `/educator_portal/posts/${postId}/comments`,
  token: getPortalAuthToken(),
}))

export const archiveComment = createPatchRequest(
  'ARCHIVE_COMMENT',
  (commentId) => ({
    url: `/educator_portal/comments/${commentId}/archive`,
    token: getPortalAuthToken(),
  })
)

export const fetchFlaggedItems = createRequest('FETCH_FLAGGGED_ITEMS', () => ({
  url: '/educator_portal/flags',
  token: getPortalAuthToken(),
}))

export const createFlag = createPostRequest(
  'CREATE_FLAG',
  ({ reviewableId, reviewableType, reason }) => ({
    url: '/educator_portal/flags',
    body: {
      flag: {
        reviewableType,
        reviewableId,
        reason,
      },
    },
    token: getPortalAuthToken(),
    onSuccess: (reviewable) => {
      const successMessage = FLAG_MESSAGE.CREATE.SUCCESS
      return { reviewable, successMessage }
    },
    onFailure: (error) => {
      const defaultMessage = FLAG_MESSAGE.CREATE.FAIL
      return { errorMessage: error.errors || defaultMessage }
    },
  })
)

export const unflag = createPatchRequest('UNFLAG', (id) => ({
  url: `/educator_portal/flags/${id}/unflag`,
  token: getPortalAuthToken(),
  onSuccess: (reviewable) => {
    const successMessage = FLAG_MESSAGE.UNFLAG.SUCCESS
    return { reviewable, successMessage }
  },
  onFailure: (error) => {
    const defaultMessage = FLAG_MESSAGE.UNFLAG.FAIL
    return { errorMessage: error.errors || defaultMessage }
  },
}))

export const agreeWithFlag = createPatchRequest('AGREE_WITH_FLAG', (id) => ({
  url: `/educator_portal/flags/${id}/agree`,
  body: {
    flag: {
      resolutionNote: Types.FLAG_RESOLUTION_NOTES.AGREE,
    },
  },
  token: getPortalAuthToken(),
  onSuccess: (reviewable) => {
    const successMessage =
      FLAG_MESSAGE.AGREE.SUCCESS[reviewable.type.toUpperCase()]
    return { reviewable, successMessage }
  },
  onFailure: (error) => {
    const defaultMessage = FLAG_MESSAGE.AGREE.FAIL
    return { errorMessage: error.message || defaultMessage }
  },
}))

export const disagreeWithFlag = createPatchRequest(
  'DISAGREE_WITH_FLAG',
  (id) => ({
    url: `/educator_portal/flags/${id}/disagree`,
    body: {
      flag: {
        resolutionNote: Types.FLAG_RESOLUTION_NOTES.DISAGREE,
      },
    },
    token: getPortalAuthToken(),
    onSuccess: (reviewable) => {
      const successMessage =
        FLAG_MESSAGE.DISAGREE.SUCCESS[reviewable.type.toUpperCase()]
      return { reviewable, successMessage }
    },
    onFailure: (error) => {
      const defaultMessage = FLAG_MESSAGE.DISAGREE.FAIL
      return { errorMessage: error.errors || defaultMessage }
    },
  })
)

export const fetchCommunitiesOfPractice = createRequest(
  'FETCH_COMMUNITIES_OF_PRACTICE',
  () => ({
    url: '/educator_portal/communities_of_practice',
    token: getPortalAuthToken(),
  })
)

export const fetchCommunityOfPractice = createRequest(
  'FETCH_COMMUNITY_OF_PRACTICE',
  (id) => ({
    url: `/educator_portal/communities_of_practice/${id}`,
    token: getPortalAuthToken(),
  })
)

export const createMembership = createPostRequest(
  'CREATE_MEMBERSHIP',
  ({ communityOfPracticeId }) => ({
    url: '/educator_portal/memberships',
    body: {
      membership: {
        communityOfPracticeId,
      },
    },
    token: getPortalAuthToken(),
    onSuccess: (userDetails) => {
      const successMessage = MEMBERSHIP_MESSAGE.CREATE.SUCCESS
      return { userDetails, successMessage }
    },
    onFailure: (error) => {
      const defaultMessage = MEMBERSHIP_MESSAGE.CREATE.FAIL
      return { errorMessage: error.errors || defaultMessage }
    },
  })
)

export const deleteMembership = createDeleteRequest(
  'DELETE_MEMBERSHIP',
  ({ membershipId }) => ({
    url: `/educator_portal/memberships/${membershipId}`,
    token: getPortalAuthToken(),
    onSuccess: (userDetails) => {
      const successMessage = MEMBERSHIP_MESSAGE.DELETE.SUCCESS
      return { userDetails, successMessage }
    },
    onFailure: (error) => {
      const defaultMessage = MEMBERSHIP_MESSAGE.DELETE.FAIL
      return { errorMessage: error.errors || defaultMessage }
    },
  })
)

export const fetchAnnouncements = createRequest(
  'FETCH_ANNOUNCEMENTS',
  (cop_id) => ({
    url: `/educator_portal/communities_of_practice/${cop_id}/announcements`,
    token: getPortalAuthToken(),
  })
)

export const createOrUpdateAnnouncement = createRequest(
  'CREATE_OR_UPDATE_ANNOUNCEMENT',
  (announcement, announcementId, communityOfPracticeId) => {
    const baseUrl = `/educator_portal/communities_of_practice/${communityOfPracticeId}/announcements`
    const method = announcementId ? 'PATCH' : 'POST'
    const url = announcementId ? `${baseUrl}/${announcementId}` : baseUrl

    return {
      url,
      method,
      body: { announcement: formatAnnouncementDate(announcement) },
      token: getPortalAuthToken(),
    }
  }
)

export const deleteAnnouncement = createDeleteRequest(
  'DELETE_ANNOUNCEMENT',
  ({ communityOfPracticeId, announcementId }) => ({
    url: `/educator_portal/communities_of_practice/${communityOfPracticeId}/announcements/${announcementId}`,
    token: getPortalAuthToken(),
    onSuccess: (announcements) => {
      const successMessage = ANNOUNCEMENT_MESSAGE.DELETE.SUCCESS
      return { announcements, successMessage }
    },
    onFailure: (error) => {
      const defaultMessage = MEMBERSHIP_MESSAGE.DELETE.FAIL
      return { errorMessage: error.errors || defaultMessage }
    },
  })
)

export const fetchAttachments = createRequest(
  'FETCH_ATTACHMENTS',
  ({ communityOfPracticeId }) => {
    return {
      url: `/educator_portal/communities_of_practice/${communityOfPracticeId}/attachments`,
      token: getPortalAuthToken(),
    }
  }
)

export const createOrUpdateAttachment = createRequest(
  'CREATE_OR_UPDATE_ATTACHMENT',
  ({ name, attachable, attachment, id }) => {
    const baseUrl = `educator_portal/attachments`
    const method = id ? 'PATCH' : 'POST'
    const url = id ? `${baseUrl}/${id}` : baseUrl

    const [attachableType, attachableId] = attachable.split('_')

    return {
      url,
      method,
      body: {
        uploadUrl: attachment.src,
        name,
        attachableType,
        attachableId,
      },
      token: getPortalAuthToken(),
    }
  }
)

export const deleteAttachment = createDeleteRequest(
  'DELETE_ATTACHMENT',
  (id) => ({
    url: `/educator_portal/attachments/${id}`,
    token: getPortalAuthToken(),
    onSuccess: (attachments) => {
      const successMessage = ATTACHMENT_MESSAGE.DELETE.SUCCESS
      return { attachments, successMessage }
    },
    onFailure: (error) => {
      const defaultMessage = ATTACHMENT_MESSAGE.DELETE.FAIL
      return { errorMessage: error.errors || defaultMessage }
    },
  })
)

export const fetchFolders = createRequest(
  'FETCH_FOLDERS',
  ({ communityOfPracticeId }) => {
    return {
      url: `/educator_portal/communities_of_practice/${communityOfPracticeId}/folders`,
      token: getPortalAuthToken(),
    }
  }
)

export const fetchFolder = createRequest(
  'FETCH_FOLDER',
  ({ communityOfPracticeId, folderId }) => {
    return {
      url: `/educator_portal/communities_of_practice/${communityOfPracticeId}/folders/${folderId}`,
      token: getPortalAuthToken(),
    }
  }
)

export const createFolder = createPostRequest(
  'CREATE_FOLDER',
  ({ name, folderable }) => {
    const [folderableType, folderableId] = folderable.split('_')

    return {
      url: '/educator_portal/folders',
      body: {
        name,
        folderableType,
        folderableId,
      },
      token: getPortalAuthToken(),
    }
  }
)

export const deleteFolder = createDeleteRequest('DELETE_FOLDER', (id) => ({
  url: `/educator_portal/folders/${id}`,
  token: getPortalAuthToken(),
  onSuccess: (folders) => {
    const successMessage = FOLDER_MESSAGE.DELETE.SUCCESS
    return { folders, successMessage }
  },
  onFailure: (error) => {
    const defaultMessage = FOLDER_MESSAGE.DELETE.FAIL
    return { errorMessage: error.errors || defaultMessage }
  },
}))

export const fetchAttachableOptions = createRequest(
  'FETCH_ATTACHABLE_OPTIONS',
  (communityOfPracticeId) => ({
    url: `/educator_portal/communities_of_practice/${communityOfPracticeId}/attachable_options`,
    token: getPortalAuthToken(),
  })
)

export const fetchFolderableOptions = createRequest(
  'FETCH_FOLDERABLE_OPTIONS',
  (communityOfPracticeId) => ({
    url: `/educator_portal/communities_of_practice/${communityOfPracticeId}/folderable_options`,
    token: getPortalAuthToken(),
  })
)

// Shared actions

export const signIn = createPostRequest(
  'SIGN_IN',
  ({ email, password, employerId, portal }) => {
    return {
      url: '/authentication/sessions',
      body: { session: { email, password, employerId }, portal },
      onFailure: (error) => {
        const redirectUrl = error.response.redirectUrl
        if (redirectUrl) {
          delayedRedirectToUrl(redirectUrl)
        }
        return { errors: { _error: error.errors } }
      },
    }
  }
)

export const signInViaSso = createPostRequest(
  'SIGN_IN_VIA_SSO',
  ({ type = 'saml', token, userId, employeeId, portal }) => {
    return {
      url: `/sso/${type}/authenticate`,
      body: { session: { userId, token, employeeId }, portal },
      onFailure: ({ response }) => ({ errors: { _error: response.message } }),
    }
  }
)

export const forgotPassword = createPostRequest(
  'FORGOT_PASSWORD',
  ({ portal, ...params }) => {
    return {
      url: '/authentication/passwords',
      body: { password: params, portal },
      onFailure: (error) => {
        const redirectUrl = error.response.redirectUrl
        if (redirectUrl) {
          delayedRedirectToUrl(redirectUrl)
        }
        return { errors: { _error: error.errors } }
      },
    }
  }
)

export const resetPassword = createPatchRequest(
  'RESET_PASSWORD',
  ({ token, password }) => {
    return {
      url: '/authentication/passwords',
      body: {
        token,
        passwordReset: { password },
      },
    }
  }
)

export const claimAccount = createPostRequest('CLAIM_ACCOUNT', (params) => {
  return {
    url: '/authentication/claim_accounts',
    body: { password: params },
    onFailure: (error) => {
      const redirectUrl = error.response.redirectUrl
      if (redirectUrl) {
        delayedRedirectToUrl(redirectUrl)
      }
      return { errors: { _error: error.errors } }
    },
  }
})

export const updateCurrentUser = createPatchRequest(
  'UPDATE_CURRENT_USER',
  (params) => {
    return {
      url: `/authentication/users`,
      body: params,
      token: getPortalAuthToken(),
    }
  }
)

export const agreeToTerms = createPatchRequest('AGREE_TO_TERMS', () => {
  return {
    url: '/authentication/agree_to_terms',
    token: getPortalAuthToken(),
  }
})

export const switchAccount = createPostRequest(
  'SWITCH_ACCOUNT',
  (employeeId) => {
    return {
      url: '/authentication/switch_accounts',
      body: { session: { employeeId } },
      token: getPortalAuthToken(),
    }
  }
)

export const markAllAsRead = createPatchRequest(
  'MARK_ALL_AS_READ',
  (notificationIds) => {
    return {
      url: 'notifications/mark_all_read',
      body: { notificationIds: notificationIds },
      token: getPortalAuthToken(),
    }
  }
)

export const markAsRead = createPatchRequest('MARK_AS_READ', (id) => ({
  url: `notifications/${id}/mark_read`,
  token: getPortalAuthToken(),
}))

export const deleteAllNotifications = createDeleteRequest(
  'DELETE_ALL_NOTIFICATIONS',
  (notificationIds) => ({
    url: 'notifications/destroy_all',
    body: { notificationIds: notificationIds },
    token: getPortalAuthToken(),
  })
)

export const deleteNotification = createDeleteRequest(
  'DELETE_NOTIFICATION',
  (id) => ({
    url: `/notifications/${id}`,
    token: getPortalAuthToken(),
  })
)

export const fetchSchoolTypes = createRequest('FETCH_SCHOOL_TYPES', () => ({
  url: '/school_categories',
}))

export const fetchDisciplines = createRequest('FETCH_DISCIPLINES', () => ({
  url: `/disciplines`,
}))

export const fetchApproaches = createRequest('FETCH_APPROACHES', () => ({
  url: `/approaches`,
}))

export const fetchObstacleTypes = createRequest('FETCH_OBSTACLE_TYPES', () => ({
  url: `/obstacles`,
}))

export const fetchStandards = createRequest('FETCH_STANDARDS', () => ({
  url: `/standards`,
}))

export const fetchGovernanceTypes = createRequest(
  'FETCH_GOVERNANCE_TYPES',
  () => ({
    url: `/governances`,
  })
)

export const fetchSchoolYears = createRequest('FETCH_SCHOOL_YEARS', () => ({
  url: `/school_years`,
}))

export const fetchNotifications = createRequest('FETCH_NOTIFICATIONS', () => ({
  url: '/notifications',
  token: getPortalAuthToken(),
}))

export const fetchNotificationPreferences = createRequest(
  'FETCH_NOTIFICATION_PREFERENCES',
  (subscriptionId) => ({
    url: `/notification_subscriptions/${subscriptionId}`,
    token: getPortalAuthToken(),
  })
)

export const updateNotificationPreferences = createPatchRequest(
  'UPDATE_NOTIFICATION_PREFERENCES',
  (subscriptionId, params) => ({
    url: `/notification_subscriptions/${subscriptionId}`,
    body: params,
    token: getPortalAuthToken(),
  })
)

export const fetchActiveSchoolYears = createRequest(
  'FETCH_ACTIVE_SCHOOL_YEARS',
  (params) => ({
    url: `/school_years`,
    query: params,
  })
)

export const fetchActiveAndPublishedSchoolYears = createRequest(
  'FETCH_ACTIVE_AND_PUBLISHED_SCHOOL_YEARS',
  (params) => ({
    url: `/school_years`,
    query: { ...params, published: true },
  })
)

export const fetchResourceTypes = createRequest('FETCH_RESOURCE_TYPES', () => ({
  url: `/resource_types`,
}))

export const fetchOutcomeTypes = createRequest('FETCH_OUTCOME_TYPES', () => ({
  url: `/outcome_types`,
  token: getPortalAuthToken(),
}))

export const fetchPositions = createRequest('FETCH_POSITIONS', () => ({
  url: `/positions`,
  token: getPortalAuthToken(),
}))

export const fetchGrades = createRequest('FETCH_GRADES', () => ({
  url: `/grades`,
  token: getPortalAuthToken(),
}))

export const fetchFinancialAssistanceTypes = createRequest(
  'FETCH_FINANCIAL_ASSISTANCE_TYPES',
  () => ({
    url: `/financial_assistance_types`,
    token: getPortalAuthToken(),
  })
)

export const fetchLeadershipCharacteristics = createRequest(
  'FETCH_LEADERSHIP_CHARACTERISTICS',
  () => ({
    url: `/leadership_characteristics`,
    token: getPortalAuthToken(),
  })
)

export const fetchIdentityFrequencies = createRequest(
  'FETCH_IDENTITY_FREQUENCIES',
  () => ({
    url: `/identity_frequencies`,
    token: getPortalAuthToken(),
  })
)

export const fetchPartnershipQuestion = createRequest(
  'FETCH_PARTNERSHIP_QUESTION',
  () => ({
    url: '/partnership_question',
    token: getPortalAuthToken(),
  })
)
