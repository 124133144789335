import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { lpForm } from 'lp-form'
import { modifyProps } from 'lp-hoc'
import { Field, propTypes as formPropTypes, FormSection } from 'redux-form'
import { persistSubmitSucceeded } from 'utils'
import { SubmitButton, ButtonArea } from 'lp-components'
import { AutoSaveStatusIndicator, InputWithWarnings } from 'components'
import { update } from 'lodash/fp'
import { memoize } from 'lodash'

const propTypes = {
  ...formPropTypes,
  ariaLabelledby: PropTypes.string.isRequired,
  threshold: PropTypes.object.isRequired,
}

const defaultProps = {}

function warnFundingAmount(value, threshold) {
  if (!value) return
  const maxDistictFundingDollars = Math.round(
    Number(threshold.maxDistrictFundingCents) / 100
  )
  if (value > maxDistictFundingDollars)
    return `Value should be less than $${maxDistictFundingDollars}`
}

const warnCourses = memoize((threshold) => {
  if (!threshold) return
  return function warn(value) {
    return warnFundingAmount(value, threshold)
  }
})

function BudgetForm({
  handleSubmit,
  saved,
  isSaving,
  submitting,
  ariaLabelledby,
  threshold,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <AutoSaveStatusIndicator {...{ saveSucceeded: saved }} />
      <fieldset disabled={isSaving}>
        <FormSection name="detail">
          <Field
            name="districtFunding.fractional"
            label={false}
            type="number"
            component={InputWithWarnings}
            className="dollar-amount col-4"
            aria-labelledby={ariaLabelledby}
            warn={warnCourses(threshold)}
          />
        </FormSection>
      </fieldset>
      <ButtonArea>
        <SubmitButton {...{ pristine: saved, submitting }}>
          Save Response
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

BudgetForm.propTypes = propTypes
BudgetForm.defaultTypes = defaultProps

function toDollarsAsInteger(num) {
  return num ? Math.round(Number(num) / 100) : num
}

function modifyInitialValues({ initialValues }) {
  return {
    initialValues: update(
      'detail.districtFunding.fractional',
      toDollarsAsInteger,
      initialValues
    ),
  }
}

export default compose(
  modifyProps(modifyInitialValues),
  lpForm({
    name: 'school-budget',
    constraints: {
      'detail.districtFunding.fractional': {
        presence: {
          message: '^Funding cannot be blank',
        },
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 0,
          notValid: '^Funding is not a number',
          notInteger: '^Funding cannot be a decimal',
          notGreaterThanOrEqualTo: '^Funding cannot be negative',
        },
      },
    },
    enableReinitialize: true,
  }),
  persistSubmitSucceeded()
)(BudgetForm)
