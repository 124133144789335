import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import moment from 'moment'
import NotificationListItem from './notification-list-item'
import { isEmpty } from 'lodash'

const propTypes = {
  notifications: PropTypes.arrayOf(Types.notification).isRequired,
}

const defaultProps = {
  notifications: [],
}

// moving this to utils
const formatNotifications = (notificationsArray) => {
  const todayMoment = moment().endOf('day')
  const yesterdayMoment = moment().add(-1, 'day').endOf('day')
  const notificationsObj = {
    today: [],
    yesterday: [],
    older: [],
  }
  const orderedNotifications = [...notificationsArray].reverse()
  orderedNotifications.map((item) => {
    const date = moment(item.createdAt)
    if (date.isSame(todayMoment, 'day')) {
      notificationsObj.today.push(item)
    } else if (date.isSame(yesterdayMoment, 'day')) {
      notificationsObj.yesterday.push(item)
    } else {
      notificationsObj.older.push(item)
    }
  })
  return notificationsObj
}

function NotificationList({ notifications }) {
  const [formattedNotifications, setFormattedNotifications] = useState({})

  useEffect(() => {
    setFormattedNotifications(formatNotifications(notifications))
  }, [notifications])

  return (
    <div className="notification-list">
      {formattedNotifications && (
        <div>
          {Object.keys(formattedNotifications).map((key) => {
            if (!isEmpty(formattedNotifications[key])) {
              const notificationItems = formattedNotifications[key]
              return (
                <div key={key}>
                  <div className="notification-list__date-title">
                    <span className="day-title">
                      {key.toString().charAt(0).toUpperCase() + key.slice(1)}
                    </span>
                  </div>
                  <div className="notification-list__items">
                    {notificationItems.map((item) => (
                      <NotificationListItem key={item.id} notification={item} />
                    ))}
                  </div>
                </div>
              )
            }
          })}
        </div>
      )}
    </div>
  )
}

NotificationList.propTypes = propTypes
NotificationList.defaultProps = defaultProps

export default React.memo(NotificationList)
