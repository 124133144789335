import React from 'react'
import PropTypes from 'prop-types'
import artlookWhite from 'images/artlook-logo-white.svg'
import Translate from './translate'
import ExternalLink from './external-link'
import CommunityImage from './community-image'
import {
  useCommunity,
  isSchoolPortalEnabled,
  isPartnerPortalEnabled,
  isGoogleTranslateEnabled,
} from 'utils'
import { get } from 'lodash'
import { Link } from 'react-router'
import classnames from 'classnames'

const propTypes = {
  hideLogins: PropTypes.bool,
}

const defaultProps = {
  hideLogins: false,
}

function Header({ hideLogins }) {
  const community = useCommunity()
  const { mapLogo, secondarySideBySideLogo } = community.defaultBranding
  const showCommunityLogo = !!get(mapLogo, 'src')

  return (
    <div>
      {community && !hideLogins && (
        <a href="#content" id="skip-nav-link">
          Skip to Main Content
        </a>
      )}
      <header className="navigation">
        <div className="left-nav">
          {showCommunityLogo ? (
            <div
              className={classnames('map-logo', {
                'side-by-side': secondarySideBySideLogo,
              })}
            >
              <CommunityImage className="logo" image={mapLogo} />
              {secondarySideBySideLogo && (
                <div className="second-logo">
                  <CommunityImage image={secondarySideBySideLogo} />
                </div>
              )}
            </div>
          ) : (
            <a href="/">
              <img className="logo" src={artlookWhite} alt="Artlook" />
            </a>
          )}
          {showCommunityLogo && (
            <div className="powered-by">
              <div className="powered-by-inner">
                <ExternalLink href="https://artlookmap.com">
                  <p>Powered by</p>
                  <img src={artlookWhite} alt="Artlook" />
                </ExternalLink>
              </div>
            </div>
          )}
        </div>
        <nav className="right-nav" aria-label="site">
          <ul>
            {isGoogleTranslateEnabled(community) && (
              <li>
                <Translate />
              </li>
            )}
            <li>
              <Link to="/">Home</Link>
            </li>
            {community && !hideLogins && (
              <React.Fragment>
                {isSchoolPortalEnabled(community) && (
                  <li>
                    <ExternalLink href={process.env.SCHOOL_PORTAL_URL}>
                      Schools Login
                    </ExternalLink>
                  </li>
                )}
                {isPartnerPortalEnabled(community) && (
                  <li>
                    <ExternalLink href={process.env.PARTNER_PORTAL_URL}>
                      Partners Login
                    </ExternalLink>
                  </li>
                )}
              </React.Fragment>
            )}
          </ul>
        </nav>
      </header>
    </div>
  )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header
