import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import { lpForm } from 'lp-form'
import { modifyProps } from 'lp-hoc'
import { Field, FieldArray, propTypes as formPropTypes } from 'redux-form'
import {
  Select,
  Input,
  SubmitButton,
  Checkbox,
  ButtonArea,
} from 'lp-components'
import {
  ReadOnlyCellInput,
  AutoSaveStatusIndicator,
  HiddenLabel,
} from 'components'
import * as Types from 'types'
import {
  range,
  isEmpty,
  get,
  memoize,
  startCase,
  partition,
  first,
} from 'lodash'
import {
  validateNested,
  omit,
  filterUnchanged,
  persistSubmitSucceeded,
  filterEmpty,
  serializeOptions,
  set,
} from 'utils'
import classnames from 'classnames'

const propTypes = {
  ...formPropTypes,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  readOnly: PropTypes.bool.isRequired,
}

const SCHEDULE_OPTIONS = [
  { key: 'Full Time', value: 'full_time' },
  { key: 'Part Time', value: 'part_time' },
]

const validateInstructor = memoize((validator) => {
  return function validate(value, allValues, props, name) {
    const instructorKey = name.split('.')[0]
    const instructor = get(allValues, instructorKey)
    if (isEmpty(instructor)) return // Don't validate empty rows
    return validator(value, allValues, props, name, instructor)
  }
})

function validatePresence(value) {
  if (!value) return 'Value required'
}

function InstructorsFieldsRow({ field, disciplineOptions, editing, onDelete }) {
  return (
    <div className="form-table-row">
      <div className="form-table-cell">
        <Field
          name={field + '.firstName'}
          validate={validateInstructor(validatePresence)}
          labelComponent={HiddenLabel}
          component={editing ? Input : ReadOnlyCellInput}
        />
      </div>
      <div className="form-table-cell">
        <Field
          name={field + '.lastName'}
          validate={validateInstructor(validatePresence)}
          labelComponent={HiddenLabel}
          component={editing ? Input : ReadOnlyCellInput}
        />
      </div>
      <div className="form-table-cell">
        <Field
          name={field + '.schedule'}
          validate={validateInstructor(validatePresence)}
          labelComponent={HiddenLabel}
          placeholder="Select"
          component={editing ? Select : ReadOnlyCellInput}
          options={SCHEDULE_OPTIONS}
        />
      </div>
      <div className="form-table-cell">
        <Field
          name={field + '.primaryFocus'}
          validate={validateInstructor(validatePresence)}
          labelComponent={HiddenLabel}
          placeholder="Select"
          component={editing ? Select : ReadOnlyCellInput}
          options={disciplineOptions}
        />
      </div>
      <div className="form-table-cell">
        <Field
          name={field + '.nonArts'}
          labelComponent={HiddenLabel}
          placeholder="None"
          component={Checkbox}
          disabled={!editing}
          options={disciplineOptions}
          enablePlaceholderOption
        />
      </div>
      <div className="form-table-cell">
        <Field
          name={field + '.email'}
          validate={validateInstructor(validatePresence)}
          labelComponent={HiddenLabel}
          type="email"
          component={editing ? Input : ReadOnlyCellInput}
        />
      </div>
      <div className="form-table-cell">
        {editing && (
          <button
            type="button"
            className="remove-row"
            onClick={onDelete}
            aria-label={'Remove ' + startCase(field)}
          >
            ×
          </button>
        )}
      </div>
    </div>
  )
}

function InstructorsFields({ fields, disciplineOptions, editing }) {
  return (
    <React.Fragment>
      <div className="scrollable-table">
        <div className="form-table">
          {fields.length > 0 && (
            <div className="form-table-heading">
              <div className="form-table-row">
                <div className="form-table-head">Instructor First Name</div>
                <div className="form-table-head">Instructor Last Name</div>
                <div className="form-table-head">Part/Full Time</div>
                <div className="form-table-head">Primary Focus</div>
                <div className="form-table-head">
                  Classroom/Non-Arts Teacher
                </div>
                <div className="form-table-head">Email</div>
                <div className="form-table-head" style={{ width: 20 }}></div>
              </div>
            </div>
          )}
          <div className="form-table-body">
            {fields.map((field, i) => (
              <InstructorsFieldsRow
                key={i}
                editing={editing}
                field={field}
                disciplineOptions={disciplineOptions}
                onDelete={() => fields.remove(i)}
              />
            ))}
          </div>
        </div>
      </div>
      {editing && (
        <button
          type="button"
          className="link-black"
          onClick={() => range(10).forEach(() => fields.push({}))}
        >
          <span aria-hidden>+</span> Add More Instructors
        </button>
      )}
    </React.Fragment>
  )
}

function ArtsInstructorForm({
  handleSubmit,
  disciplines,
  saved,
  submitting,
  editing,
  setEditing,
  reset,
  readOnly,
}) {
  const disciplineOptions = useMemo(
    () => serializeOptions(disciplines),
    [disciplines]
  )

  const showEditButton = !editing && !readOnly

  return (
    <form onSubmit={handleSubmit} noValidate disabled={!editing}>
      <AutoSaveStatusIndicator saveSucceeded={saved} />
      <FieldArray
        name="instructors"
        disciplineOptions={disciplineOptions}
        editing={editing}
        setEditing={setEditing}
        component={InstructorsFields}
      />
      <ButtonArea>
        {/* Submit button is required when a form exists */}
        <div className={classnames({ 'visually-hidden': !editing })}>
          <SubmitButton
            {...{ pristine: saved, submitting }}
            disabled={!editing}
          >
            Save Response
          </SubmitButton>
          <button
            type="button"
            className="button-grey-light"
            onClick={() => {
              reset()
              setEditing(false)
            }}
            disabled={!editing}
          >
            Cancel
          </button>
        </div>
        {showEditButton && (
          <button
            type="button"
            className="button-grey-light"
            onClick={() => setEditing(true)}
          >
            Edit Response
          </button>
        )}
      </ButtonArea>
    </form>
  )
}

ArtsInstructorForm.propTypes = propTypes

// Convert "primaryFocus" to "focuses" array
function toFocusRelations(instructor) {
  const focuses = []
  if (instructor.primaryFocus)
    focuses.push({
      focusableId: instructor.primaryFocus,
      focusableType: 'Discipline',
      primary: true,
    })

  const newInstructor = { ...instructor, focuses }
  return omit(newInstructor, 'primaryFocus')
}

// Convert "focuses" array to "primaryFocus"
function fromFocusRelations(instructor) {
  const [primaryFocusObj] = getFocusObjects(
    instructor.focuses
  )
  const primaryFocus = primaryFocusObj ? primaryFocusObj.focusableId : ''
  const newInstructor = { ...instructor, primaryFocus }
  return omit(newInstructor, 'focuses')
}

// Returns an array of objects or undefined, where the primary resource comes first
function getFocusObjects(focuses) {
  return partition(focuses, 'primary').map(first)
}

// Mark a resource for destruction with the minimally acceptable data required
function markForDestruction(resource) {
  return { id: resource.id, _destroy: true }
}

// Filter, replace, or mark existing focuses for destruction (e.g., Secondary Focus is changed from "Music" to "None")
function filterUnchangedFocuses({ initial, new: newFocuses }) {
  return newFocuses.reduce((acc, focus) => {
    const isPrimary = !!focus && focus.primary
    const existingFocus = initial.find(({ primary }) => primary === isPrimary)

    if (existingFocus) {
      if (!focus) return [...acc, markForDestruction(existingFocus)] // deleting existing
      if (existingFocus.focusableId === Number(focus.focusableId)) return acc // no change

      const updatedFocus = { ...focus, id: existingFocus.id }
      return [...acc, updatedFocus]
    } else if (focus) {
      return [...acc, focus] // adding new focus
    } else {
      return acc
    }
  }, [])
}

// Only send modified resources
function modifyBeforeSubmit({ unmodifiedInitialValues, initialValues }) {
  return {
    beforeSubmit: ({ instructors }) => {
      const oldInstructors = initialValues.instructors
      const newInstructors = filterEmpty(instructors)
      const changedInstructors = filterUnchanged({
        initial: oldInstructors,
        new: newInstructors,
      })

      const changedInstructorsWithFocuses = changedInstructors.map(
        (changedInstructor) => {
          const instructorWithFocuses = toFocusRelations(changedInstructor)
          if (!changedInstructor.id || changedInstructor._destroy)
            return instructorWithFocuses // break if instructor is new or getting deleted

          const existingInstructor = unmodifiedInitialValues.instructors.find(
            ({ id }) => changedInstructor.id === id
          )

          const changedFocuses = filterUnchangedFocuses({
            initial: existingInstructor.focuses,
            new: getFocusObjects(instructorWithFocuses.focuses),
          })

          return set('focuses', changedFocuses, instructorWithFocuses)
        }
      )

      return { instructors: changedInstructorsWithFocuses }
    },
  }
}

function modifyInitialValues({ initialValues }) {
  return {
    unmodifiedInitialValues: initialValues,
    initialValues: {
      ...initialValues,
      instructors: initialValues.instructors.map(fromFocusRelations),
    },
  }
}

// Set editing to false on success
function modifyOnSubmitSuccess({ onSubmitSuccess, setEditing }) {
  return {
    onSubmitSuccess: (...args) => {
      setEditing(false)
      return onSubmitSuccess(...args)
    },
  }
}

export default compose(
  withState('editing', 'setEditing', false),
  modifyProps(modifyInitialValues),
  modifyProps(modifyBeforeSubmit),
  modifyProps(modifyOnSubmitSuccess),
  lpForm({
    name: 'school-arts-instructor',
    enableReinitialize: true,
    validate: validateNested({
      'instructors[].email': { email: true },
    }),
  }),
  persistSubmitSucceeded()
)(ArtsInstructorForm)
