import logo from 'images/communities/NWArkansas/wac-logo-horizontal.svg'
import secondaryLogo from 'images/communities/NWArkansas/cache-logo.png'
import dashboardHeaderImage from 'images/communities/NWArkansas/banner.jpg'
import mapHeaderImage from 'images/communities/NWArkansas/landing.jpg'
import loginBackgroundImg from 'images/communities/NWArkansas/background-image.jpg'
import favicon from 'images/communities/NWArkansas/favicon.png'
import homeLogo from 'images/home/community-logos/nw-arkansas.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'nwarkansas',
  name: 'Northwest Arkansas',
  position: 10,
  partnerSupportEmail: 'artlook@waltonartscenter.org',
  schoolSupportEmail: 'artlook@waltonartscenter.org',
  stylesheet: 'nwarkansas.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://waltonartscenter.org/',
  secondaryOrganizationUrl: 'https://cachecreate.org/',
  faqUrl:
    'https://drive.google.com/file/d/1TfWwtrKOSboVlfn8qomaisNid41JW2ei/view?usp=sharing',
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: false,
  notificationsEnabled: true,
  id: 46,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory'],
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 36.16542, lng: -93.9 },
    radius: 40, //in miles
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'nwa-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: 'Walton Arts Center',
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'Walton Arts Center',
  },
  secondaryFooterLogo: {
    src: secondaryLogo,
    alt: 'CACHE - Creative Arkansas Community Hub & Exchange',
    link: config.secondaryOrganizationUrl,
  },
  communityLogo: {
    src: logo,
    alt: 'Walton Arts Center - Artlook',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: mapHeaderImage,
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title: 'artlook Map: Increasing Arts Education in Arkansas Public Schools',
    searchHeader: 'Find Schools & Partners in Arkansas',
    welcomeSearchHeader: 'Explore Arts Education in Arkansas',
    welcomeHeader: `artlook<sup>®</sup> Arkansas`,
    welcomeMessage: `
      <p>
        artlook<sup>®</sup> is your one-stop-shop for information on arts education in Northwest Arkansas. With thousands of data points, artlook<sup>®</sup> provides the information you need to make strategic decisions about expanding the arts in Arkansas public schools.
      </p>
      <p>
        Walton Arts Center, in partnership with CACHE, has developed this platform to identify where the arts exist and illuminate where the arts are needed. This tool can show you everything from which arts teachers and arts partners are in schools to what arts programming, courses and minutes of instruction is happening where.
      </p>
      <p>
        Start exploring artlook<sup>®</sup> today, and help expand arts education access, quality and equity for every child, in every grade, in every school.
      </p>
    `,
  },
  tagline:
    "Let's increase arts education access, equity and quality in Arkansas",
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Arkansas</em> is YOUR gallery for arts education data</h3>
        <p>
          artlook<sup>®</sup> Arkansas is the only platform of its kind that combines data from Arkansas public schools and arts education program providers (like you!). The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY student in our community.
        </p>
        <p>
          <strong>
            To get started, follow the link below to complete a brief survey about your school's arts education offerings and partnerships.
          </strong>
        </p>
        <p>If you have any questions or need assistance, please contact <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>.</p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Arkansas</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> Arkansas</em> is the only platform of its kind that combines data from Arkansas public schools (like you!) and arts education program providers. The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY student in our community.
        </p>
        <p>
          <strong>
            To get started, follow the link below to complete a brief survey about your school's arts education offerings and partnerships.
          </strong>
        </p>
        <p>If you have any questions or need assistance, please contact <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.</p>
      `,
    },
  },
  // question: what are these here for then?
  survey: {
    getStarted: `<h2>Welcome to the {{ surveySchoolYearNumber }} Artlook School Survey!</h2>
    <p>Through this annual survey, you will be asked to share information about arts education staffing and instruction at your school, partnerships with arts partners (i.e. arts organizations), and how your school budgets and plans for the arts.</p>
    <p>As you work on the {{ surveySchoolYearNumber }} survey, please be sure to frequently save your responses to each question to avoid losing information. You are not required to complete your survey in one session; saving your responses as you go will allow you to return at any time to complete or edit your survey before submitting.</p>
    <p>Please feel free to reach out to <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a> with any questions!</p>`,
    staffingModal: `
    <p>
    <strong>Note on instructors:</strong> Restrict the list of instructors in your school to those teaching an arts course during the school day.
    </p>
    <p>
    <strong>Note on part/full-time status:</strong> Please select Full Time if a teacher had full-time status for the entire school year. If a teacher was part-time for all or part of the year, or full-time for only part of the year, please select Part Time.
    </p>
    <p>
    <strong>Note on disciplines:</strong> The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
    </p>
    `,
  },
}

// ----- SURVEY QUESTIONS CONFIG -----

const {
  APPROACHES,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.GENERAL,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

export default config
