import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Field, propTypes as formPropTypes } from 'redux-form'
import { lpForm } from 'lp-form'
import { ButtonArea, SubmitButton, Input } from 'lp-components'

const propTypes = {
  ...formPropTypes,
  ariaLabelledby: PropTypes.string.isRequired,
}

const defaultProps = {}

function AddPendingPartnerForm({ handleSubmit, submitting, ariaLabelledby }) {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="organizationName"
        label={false}
        component={Input}
        aria-labelledby={ariaLabelledby}
      />
      <ButtonArea>
        <SubmitButton {...{ submitting, style: 'secondary' }}>
          Create Pending Partnership
        </SubmitButton>
      </ButtonArea>
    </form>
  )
}

AddPendingPartnerForm.propTypes = propTypes
AddPendingPartnerForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'school-add-pending-partner',
    constraints: {
      organizationName: { presence: true },
    },
  })
)(AddPendingPartnerForm)
